import { EventEmitter, Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HttpHeaders,HttpRequest,HttpParams  } from '@angular/common/http';
// import { RequestOptions,Headers,Http } from '@angular/http';
import { Customer } from './customer';

@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {
  apiURL: string =  'https://kid-seeds.com/nursery/public/api_dev/contactus';
  apiURL2: string =  'https://kid-seeds.com/nursery/public/api/visits';
  apiURL3: string =  'https://kid-seeds.com/nursery/public/api_dev/create-nursery';



  changedLang: EventEmitter<any> = new EventEmitter<any>();
  constructor(private httpClient: HttpClient) { }
        
  public createCustomer(formValue){
    console.log("service=>", formValue)
    let body = {
      "name":formValue.name,
      "email":formValue.email,
      "mobile":formValue.mobile,
      "message_text":formValue.message_text
    }

    console.log("body =>" ,body)

    return this.httpClient.post<any>(`${this.apiURL}`,body)
  
  }


  GetVisits(){
    return this.httpClient.post<any>(`${this.apiURL2}`,{})

  }

  NurseryRegisteration(formValue){
    return this.httpClient.post<any>(`${this.apiURL3}`,formValue)
  }


  
   



}
