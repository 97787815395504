
<!-- <div  dir="{{ 'dir' | translate }}" [class.rtl]="('dir' | translate) === 'rtl'"> -->
<div [ngClass]="'currentTheme' | translate">
    <div class="base-align">
        <router-outlet></router-outlet>
    </div>
</div>



