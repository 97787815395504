
<div class="container-fluid mainstyle" id="parent">
  <div class="row norow">


    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 left-padding hidden-xs" [@expansionTrigger]="state">
        <img class="img-responsive bottom-space" src="assets/images/nursery_images/images2/grey-text-icon.png">
     
        <p class="paragraph"> <span class="title">{{'HOME.HEALTH'|translate}} _ </span>{{'HOME.PARENTS_HEALTH'|translate}} </p>
        <p class="paragraph"> <span class="title">{{'HOME.LEARNING'|translate}} _ </span>{{'HOME.PARENTS_LEARNING'|translate}}</p>
        <p class="paragraph"> <span class="title">{{'HOME.COMMUNICATION'|translate}} _ </span>{{'HOME.PARENTS_COMMUNICATION'|translate}}</p>
        <p class="paragraph"> <span class="title">{{'HOME.BEHAVIOR'|translate}} _ </span>{{'HOME.PARENTS_BEHAVIOR'|translate}}</p>
        <p class="paragraph"> <span class="title">{{'HOME.SAFETY'|translate}} _ </span>{{'HOME.PARENTS_SAFETY'|translate}}</p>
  
  
        <img class="img-responsive right" src="assets/images/nursery_images/images2/grey-text-icon.png">
    </div>
    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 nopadding hidden-xs"  [@scrollAnimation]="state">

        <div class="pink-bg grow">
            <p class="main-title1">{{'HOME.FOR'|translate}}</p>
            <p class="main-title2"> {{'HOME.PARENT'|translate}}</p>
          </div>
            <img class="img-responsive right parent-img" src="assets/images/nursery_images/{{'IMG.PARENTS'|translate}}">
       

    </div>



    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 nopadding display-xs hidden-lg" >

      <div class="pink-bg">
        <p class="main-title1">{{'HOME.FOR'|translate}}</p>
        <p class="main-title2"> {{'HOME.PARENT'|translate}}</p>
        </div>
          <img class="img-responsive right" src="assets/images/nursery_images/{{'IMG.PARENTS'|translate}}">
     

    </div>
    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 left-padding display-xs hidden-lg" >
      <img class="img-responsive bottom-space" src="assets/images/nursery_images/images2/grey-text-icon.png">
   
      <p class="paragraph"> <span class="title">{{'HOME.HEALTH'|translate}} _ </span>{{'HOME.PARENTS_HEALTH'|translate}} </p>
        <p class="paragraph"> <span class="title">{{'HOME.LEARNING'|translate}} _ </span>{{'HOME.PARENTS_LEARNING'|translate}}</p>
        <p class="paragraph"> <span class="title">{{'HOME.COMMUNICATION'|translate}} _ </span>{{'HOME.PARENTS_COMMUNICATION'|translate}}</p>
        <p class="paragraph"> <span class="title">{{'HOME.BEHAVIOR'|translate}} _ </span>{{'HOME.PARENTS_BEHAVIOR'|translate}}</p>
        <p class="paragraph"> <span class="title">{{'HOME.SAFETY'|translate}} _ </span>{{'HOME.PARENTS_SAFETY'|translate}}</p>
  

      <img class="img-responsive right" src="assets/images/nursery_images/images2/grey-text-icon.png">
    </div>

    
      
    

  </div>
</div>

