
<div class="container-fluid mainstyle" id="tour">
  <div class="row norow">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 hidden-xs">
      <img class="img-responsive blue-box loading" src="assets/images/nursery_images/images2/grey-small-box.png">
    </div>

    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 left-padding hidden-xs" [@expansionTrigger]="state">

      <img class="img-responsive bottom-space" src="assets/images/nursery_images/images2/orange-text-icon.png">
     
      <p class="paragraph "> <span class="title">{{'HOME.HEALTH'|translate}}_ </span> {{'HOME.MANAGEMENT_HEALTH'|translate}} </p>
      <p class="paragraph"> <span class="title">{{'HOME.LEARNING'|translate}}_ </span> {{'HOME.MANAGEMENT_LEARNING'|translate}}</p>
      <p class="paragraph"> <span class="title">{{'HOME.COMMUNICATION'|translate}}_</span> {{'HOME.MANAGEMENT_COMMUNICATION'|translate}}</p>
      <p class="paragraph"> <span class="title">{{'HOME.BEHAVIOR'|translate}}_ </span> {{'HOME.MANAGEMENT_BEHAVIOR'|translate}}</p>
      <p class="paragraph"> <span class="title"> {{'HOME.SAFETY'|translate}}_</span> {{'HOME.MANAGEMENT_SAFETY'|translate}}</p>


      <img class="img-responsive right" src="assets/images/nursery_images/images2/orange-text-icon.png">


    </div>

    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 nopadding hidden-xs" [@scrollAnimation]="state">
        <div class="blue-bg grow">
            <p class="main-title1">{{'HOME.FOR'|translate}}</p>
            <p class="main-title2"> {{'HOME.MANAGEMENT'|translate}}</p>
          </div>
            <img class="img-responsive right manager-img" src="assets/images/nursery_images/{{'IMG.MANAGEMENT'|translate}}">
    </div>

    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 nopadding display-xs hidden-lg">
      <div class="blue-bg">
          <p class="main-title1">{{'HOME.FOR'|translate}}</p>
          <p class="main-title2"> {{'HOME.MANAGEMENT'|translate}}</p>
        </div>
          <img class="img-responsive right" src="assets/images/nursery_images/{{'IMG.MANAGEMENT'|translate}}">
  </div>

    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 left-padding display-xs hidden-lg" >

      <img class="img-responsive bottom-space" src="assets/images/nursery_images/images2/orange-text-icon.png">
     
      <p class="paragraph "> <span class="title">{{'HOME.HEALTH'|translate}}_ </span> {{'HOME.MANAGEMENT_HEALTH'|translate}} </p>
      <p class="paragraph"> <span class="title">{{'HOME.LEARNING'|translate}}_ </span> {{'HOME.MANAGEMENT_LEARNING'|translate}}</p>
      <p class="paragraph"> <span class="title">{{'HOME.COMMUNICATION'|translate}}_</span> {{'HOME.MANAGEMENT_COMMUNICATION'|translate}}</p>
      <p class="paragraph"> <span class="title">{{'HOME.BEHAVIOR'|translate}}_ </span>  {{'HOME.MANAGEMENT_BEHAVIOR'|translate}}</p>
      <p class="paragraph"> <span class="title"> {{'HOME.SAFETY'|translate}}_</span>{{'HOME.MANAGEMENT_SAFETY'|translate}}</p>


      <img class="img-responsive right" src="assets/images/nursery_images/images2/orange-text-icon.png">


    </div>

    
    
  </div>
</div>

