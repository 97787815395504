
<div class="container main-style" id="subscription">
  <div class="row norow">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 center">
       
        <img class="img-responsive center" src="assets/images/nursery_images/images2/value-green-icon.png">
        <p class="main-title">SUBSCRIPTION</p>
      
    </div>
    <!-- <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12"></div> -->

    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-border col-0 bottom-space">

      <p class="left-text">Attendence</p>
      <p class="left-text"> Daily Mood Report</p>
      <p class="left-text"> Daily Meal Report</p>
      <p class="left-text">Daily Academic and Activities</p>
      <p class="left-text">Daily Toilet Report</p>
      <p class="left-text">Daily Nap Time Report</p>
      <p class="left-text">Messages</p>
      <p class="left-text">Announcements</p>
      <p class="left-text">Daily Full Report</p>
      <p class="left-text">Mogo Games</p>
      <p class="left-text">Media View</p>
      <p class="left-text">Download Media</p>
      <p class="left-text">Emergency Call</p>
      <p class="left-text">Medicines</p>
      <p class="left-text">Monthly Report</p>
      <p class="left-text">Statistics For Parent</p>

      <p class="left-text">Download Academic Files</p>
      <p class="left-text">Notification</p>
      <p class="left-text">Notification Setting</p>
      <p class="left-text">GPS Tracking</p>


    </div>
    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 col-border1 center no-padding bottom-space">
        <p class="title1 bronze">BRONZE </p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i> 2-msg per day</p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>

        <!-- <p class="bronze-price"> free</p> -->

        <button class="btn bronze-btn"> BUY </button>




    </div>
    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 col-border1 center no-padding bottom-space">
      <p class="title2 silver">SILVER</p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i>5-msg per day</p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i>2-app</p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-times wrong-color"></i></p>
        <!-- <p class="silver-price"> 45 EGP</p> -->
        <button class="btn silver-btn"> BUY </button>



    </div>
    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 col-border1 center no-padding bottom-space">
        <p class="title3 gold">Gold</p>

        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i> unlimited</p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i> All app</p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>
        <p class="left-text"><i class="fa fa-check right-color"></i></p>

        <!-- <p class="gold-price"> 65 EGP</p> -->
        <button class="btn gold-btn"> BUY </button>

    </div>
    <!-- <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12"></div> -->

  </div>
</div>
