import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nursery-main',
  templateUrl: './nursery-main.component.html',
  styleUrls: ['./nursery-main.component.scss']
})
export class NurseryMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
