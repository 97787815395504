<div class="container-fluid">
    <app-nursery-header></app-nursery-header>

    <app-nursery-view-user></app-nursery-view-user>
     
    <!-- Start Footer -->
        <div class="container-fluid powered" style="background-color: #555555 !important;">
  
            <div class="row row-top norow footer-space">
        
              <div class="col-lg-4 col-md-3 col-sm-12 col-xs-12 mob-center">
                  <img class="img-responsive footer-logo" src="assets/images/nursery_images/new-img/logo_footer-new.png">
        
                 <!-- <h2 class="logo">Seeds ...</h2> -->
                  
                  <a href="https://www.facebook.com/KidSeeds/" target="blank"> <i class="fa fa-facebook-f social-icon facebook-icon"></i> </a> 
                   <a href=" https://www.instagram.com/kidseeds/" target="blank"> <i class="fa fa-instagram social-icon instagram-icon"></i> </a> 
                  <!-- <a href="" target="blank"> <i class="fa fa-twitter social-icon"></i> </a>  -->
                  <!-- <a href="https://www.linkedin.com/company/pentavalue" target="blank"><i class="fa fa-linkedin social-icon linkedin-icon"></i></a> -->
                  <!-- <a href="" target=""> <i class="fa fa-whatsapp social-icon"></i> </a>  -->
              </div>
                   <div class="col-lg-4 col-md-2 col-sm-12 col-xs-12 mob-center">
                <p class="title"> {{'HOME.EG_ADDRESS'|translate}}</p>
                <p class="paragraph">{{'HOME.EG_ADDRESS_VALUE'|translate}}</p>
                <p class="paragraph">+20 100 302 2122</p> 
              </div>
        
              <div class="col-lg-4 col-md-3 col-sm-12 col-xs-12 mob-center">
                <p class="title"> {{'HOME.SA_ADDRESS'|translate}}</p>
                <p class="paragraph">{{'HOME.SA_ADDRESS_VALUE'|translate}}</p>
                <p class="paragraph">0096655 202 9500</p>
                <!-- <p class="paragraph"></p> -->
                  <!-- <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="200" id="gmap_canvas" src="https://maps.google.com/maps?q=ahmed%20tayseer%20street%20high%20point&t=&z=11&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                    </div><style>.mapouter{position:relative;text-align:$right;height:200px;width:100%;}.gmap_canvas {overflow:hidden;background:none!important;height:200px;width:100%;}</style></div> -->
              </div>
        
              
            </div>
          
          
        </div>
        
        <div class="container-fluid powered">
              <div class="container">
                  <div class="row norow">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                      <p class="copyright footer-title3">{{'FOOTER.COPY_RIGHT'|translate}} - {{'FOOTER.ALL_RIGHT'|translate}}  <a class="pentavalue" href="http://www.pentavalue.com/">{{'FOOTER.PENTAVALUE'|translate}} </a></p>
                    </div>
                </div>
            </div>
        </div>
    <!-- End Footer -->


</div>