
<div class="container-fluid mainstyle" id="theapp">
  <!-- <div class="row"></div> -->
  <div class="container">
      <div class="row">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 center">
          <img class="img-responsive center app-logo" src="assets/images/nursery_images/images2/value-green-icon.png">
          <p class="main-title">{{'HEADER.THEAPP'|translate}}</p>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 {{'DIR.NORIGHT'|translate}} hidden-xs nopadding" [@expansionTrigger]="state">

            
          <div class="text clearfix">
              <div class="">
                  <p class="paragraph">{{'HOME.EMERGENCY_NOTIFICATION'|translate}} </p>
                  <img class="img-responsive  icon-size1"  src="assets/images/nursery_images/emergency-icon.png">
              </div>
            </div>

          <div class="text clearfix">
              <div class="">
                  <p class="paragraph">{{'HOME.MULTIMEDIA'|translate}} </p>
                  <img class="img-responsive  icon-size1"  src="assets/images/nursery_images/media-icon.png">
              </div>
            </div>

            <div class="text clearfix">
              <div class="">
                  <p class="paragraph">{{'HOME.GPSTRACKING'|translate}} </p>
                  <img class="img-responsive  icon-size1"  src="assets/images/nursery_images/gps-icon.png">
              </div>
            </div>
          <!-- <div class="text">
            <span><img class="img-responsive icon-size" src="../../../assets/images/nursery_images/gps-icon.png"> <span class="paragraph"> GPS Tracking <span class="small-text">coming soon</span></span> </span>
            
          </div> -->

        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 center hidden-xs nopadding">
          <img class="img-responsive mobile-app" src="assets/images/nursery_images/new-img/{{'IMG.APP'|translate}}">
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 {{'DIR.NOLEFT'|translate}} hidden-xs nopadding"  [@scrollAnimation]="state">

              <div class="text1 clearfix">
                  <span> <span class="paragraph1"> {{'HOME.HEALTH&MEDICATIONS_TRACKING'|translate}}  </span> <img class="img-responsive right icon-size"  src="assets/images/nursery_images/images2/health-icon.png"> </span>
                  <!-- <p class="paragraph1">Health & Medications Tracking></p> -->
                  <!-- <img class="img-responsive" src="../../../assets/images/nursery_images/images2/health-icon.png"> -->
              </div>
              <div class="text1 clearfix">
                <span> <span class="paragraph1"> {{'HOME.DAILY_REPORTS'|translate}} </span> <img class="img-responsive right icon-size" src="assets/images/nursery_images/report-icon.png"> </span>
                  <!-- <p class="paragraph1"> Daily Reports</p> -->
                  <!-- <img class="img-responsive" src="../../../assets/images/nursery_images/report-icon.png"> -->
              </div>
              <div class="text1 clearfix">
                <span>  <span class="paragraph1"> {{'HOME.DETAILED_STATISTICS'|translate}} </span> <img class="img-responsive right icon-size" src="assets/images/nursery_images/images2/statistics-icon.png"></span>
                  <!-- <p class="paragraph1"> Detailed Statistics </p> -->
                  <!-- <img class="img-responsive" src="../../../assets/images/nursery_images/images2/statistics-icon.png"> -->
              </div>
              

        </div>

        

        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 {{'DIR.NORIGHT'|translate}} display-xs hidden-lg nopadding">

            
          <div class="text clearfix">
              <div class="">
                  <p class="paragraph">Emergency Notification </p>
                  <img class="img-responsive  icon-size1"  src="assets/images/nursery_images/emergency-icon.png">
              </div>
            </div>

          <div class="text clearfix">
              <div class="">
                  <p class="paragraph">{{'HOME.MULTIMEDIA'|translate}} </p>
                  <img class="img-responsive  icon-size1"  src="assets/images/nursery_images/media-icon.png">
              </div>
            </div>

            <div class="text clearfix">
              <div class="">
                  <p class="paragraph">{{'HOME.GPSTRACKING'|translate}} </p>
                  <img class="img-responsive  icon-size1"  src="assets/images/nursery_images/gps-icon.png">
              </div>
            </div>

        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 center display-xs hidden-lg nopadding">
          <img class="img-responsive mobile-app" src="assets/images/nursery_images/new-img/{{'IMG.APP'|translate}}">
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 {{'DIR.NOLEFT'|translate}} display-xs hidden-lg nopadding">

              <div class="text1 clearfix">
                  <span> <span class="paragraph1">  {{'HOME.HEALTH&MEDICATIONS_TRACKING'|translate}} </span> <img class="img-responsive right icon-size"  src="assets/images/nursery_images/images2/health-icon.png"> </span>
              </div>
              <div class="text1 clearfix">
                <span> <span class="paragraph1"> {{'HOME.DAILY_REPORTS'|translate}}</span> <img class="img-responsive right icon-size" src="assets/images/nursery_images/report-icon.png"> </span>
              </div>
              <div class="text1 clearfix">
                <span>  <span class="paragraph1"> {{'HOME.DETAILED_STATISTICS'|translate}} </span> <img class="img-responsive right icon-size" src="assets/images/nursery_images/images2/statistics-icon.png"></span>
              </div>
              

        </div>

      </div>
  </div>

</div>

