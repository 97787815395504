import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  
}from '@angular/animations';

@Component({
  selector: 'app-nursery-values',
  templateUrl: './nursery-values.component.html',
  styleUrls: ['./nursery-values.component.scss'],

   
  animations: [

    trigger('expansionTrigger', [
      state('show', style({
        opacity: 1,
        transform: "translateX(-280%)"
      })),
      state('hide',   style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('show2', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide2',   style({
        opacity: 1,
        transform: "translateX(-280%)"
      })),

      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('800ms ease-in')),
      transition('show2 => hide2', animate('800ms ease-out')),
      transition('hide2 => show2', animate('800ms ease-in')),


      
    ]),

    trigger('scrollAnimation', [
      state('show', style({
        opacity: 1,
        transform: "translateX(280%)"
      })),
      state('hide',   style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('show2', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide2',   style({
        opacity: 1,
        transform: "translateX(280%)"
      })),

      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('800ms ease-in')),
      transition('show2 => hide2', animate('800ms ease-out')),
      transition('hide2 => show2', animate('800ms ease-in')),


      
    ]),

  ],

})
export class NurseryValuesComponent implements OnInit {

  state = 'show'
  constructor(public el:ElementRef) { }

  ngOnInit() {
  }
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition =(this.el.nativeElement.offsetTop  - 400)
    const scrollPosition = window.pageYOffset

    if (scrollPosition >= componentPosition) {
      this.state = 'show'
      this.state='show2'
    } else {
      this.state = 'hide'
      this.state = 'hide2'

    }

  }

}
