<section class="builder-section container page-margin-top" style="margin-bottom: 70px;">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h3 class="builder-main-title"> 
          {{'HEADER.Register'|translate}}
        </h3>
        
        <div class="steps">

           <div class="row">
            <form class="form" [formGroup]="registerForm" (ngSubmit)="submitForm(registerForm)"> 

                <div class="row">
                    <div class="col-md-12">
                        <h4 class="main-title txt-right">
                            {{'Dashboard Information'|translate}}:-
                        </h4>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input class="form-control" formControlName="username" placeholder="{{'Username'|translate}}" type="text"  required> <!--[formControl]="name"-->
                            <div *ngIf="registerForm.controls.username.touched && registerForm.controls.username.errors?.required" class="text text-danger">
                                {{'Username'|translate}} {{'required'|translate}}
                            </div>
                             
                        
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input class="form-control" formControlName="nurseryName" placeholder="{{'Nursery Name'|translate}}" type="email"  required>
                            <div *ngIf="registerForm.controls.nurseryName.touched && registerForm.controls.nurseryName.errors?.required" class="text text-danger">
                                {{'Nursery Name'|translate}} {{'required'|translate}}
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            
                            <input class="form-control" formControlName="mobile" placeholder="{{'Phone Number'|translate}}" type="text"  required> <!--[formControl]="name"-->
                            <div *ngIf="registerForm.controls.mobile.touched && registerForm.controls.mobile.errors?.required" class="text text-danger">
                                {{'Phone Number'|translate}} {{'required'|translate}}
                            </div>
                            <!-- <div *ngIf="registerForm.controls.mobile.touched && registerForm.controls.mobile.errors?.pattern" class="text text-danger">
                                 {{'The mobile must be at least 9 characters'|translate}}
                            </div>  -->
                            <div *ngIf="registerForm.controls.mobile.touched && registerForm.controls.mobile.errors?.minlength" class="text text-danger">
                                {{'The mobile must be at least 9 characters'|translate}}
                           </div> 
                           <div *ngIf="registerForm.controls.mobile.touched && registerForm.controls.mobile.errors?.maxlength" class="text text-danger">
                            {{'The mobile must not exceed 11 characters'|translate}}
                       </div> 

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input class="form-control" formControlName="password"  placeholder="{{'password'|translate}}" [type]="confirmPassword" value="secret"  required>
                            <span toggle="#password-fielde" class="fa fa-fw fa-eye field-icon toggle-password" (click)="changConfirmPasswordVisiabilty()">
                            </span>
                            <span *ngIf="registerForm.controls.password.touched && registerForm.controls.password.errors?.required" class="text text-danger">
                                {{'password'|translate}} {{'required'|translate}}
                            </span>
                            <span *ngIf="registerForm.controls.password.touched && registerForm.controls.password.errors?.minlength" class="text text-danger">
                                {{'The password must be at least 8 characters'|translate}}
                           </span> 

                           <span *ngIf="registerForm.controls.password.touched && registerForm.controls.password.errors?.maxlength" class="text text-danger">
                            {{'The password must not exceed 25 characters'|translate}}
                       </span> 
                            
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="upload-btn-wrapper  form-control">
                                <button class="btnr"  type="button">
                                    <i class="fa fa-upload"></i>
                                    {{'choose logo'|translate}}
                                </button>
                                {{imageName}}
                                <input  formControlName="logo" (change)="readImage($event)"  placeholder="{{'Nursery Image'|translate}}" type="file" accept=" .png , .jpg , .jpeg , .doc, .docx , .pdf" required> <!--[formControl]="name"-->
                            </div>
                           

                            
                            <div *ngIf="registerForm.controls.logo.touched && registerForm.controls.logo.errors?.required" class="text text-danger">
                                {{'Nursery Image'|translate}} {{'required'|translate}}
                            </div>
                            

                        </div>

                    </div>
                </div>

            <div class="row separator"></div>

                <div class="row">
                    <div class="col-md-12">
                        <h4 class="main-title txt-right">
                            {{'Class and Teacher Information'|translate}}:-
                        </h4>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input class="form-control" formControlName="className" placeholder="{{'Class Name'|translate}}" type="text"  required> <!--[formControl]="name"-->
                            <div *ngIf="registerForm.controls.className.touched && registerForm.controls.className.errors?.required" class="text text-danger">
                                {{'Class Name'|translate}} {{'required'|translate}}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input class="form-control" formControlName="teacherName" placeholder="{{'Teacher Name'|translate}}" type="email"  required>
                            <div *ngIf="registerForm.controls.teacherName.touched && registerForm.controls.teacherName.errors?.required" class="text text-danger">
                                {{'Teacher Name'|translate}} {{'required'|translate}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input class="form-control" formControlName="teacherNumber" placeholder="{{'Phone Number'|translate}}" type="text"  required> <!--[formControl]="name"-->
                            <div *ngIf="registerForm.controls.teacherNumber.touched && registerForm.controls.teacherNumber.errors?.required" class="text text-danger">
                                {{'Phone Number'|translate}} {{'required'|translate}}
                            </div>
                            <div *ngIf="registerForm.controls.teacherNumber.touched && registerForm.controls.teacherNumber.errors?.minlength" class="text text-danger">
                                {{'The mobile must be at least 9 characters'|translate}}
                           </div> 
                           <div *ngIf="registerForm.controls.teacherNumber.touched && registerForm.controls.teacherNumber.errors?.maxlength" class="text text-danger">
                                {{'The mobile must not exceed 11 characters'|translate}}
                          </div> 

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input class="form-control"  formControlName="teacherPassword" placeholder="{{'password'|translate}}"  [type]="password" value="secret"  required>
                            <span toggle="#password-fielde" class="fa fa-fw fa-eye field-icon toggle-password"(click)="changPasswordVisiabilty()">
                            </span>
                            <span *ngIf="registerForm.controls.teacherPassword.touched && registerForm.controls.teacherPassword.errors?.required" class="text text-danger">
                                {{'password'|translate}} {{'required'|translate}}
                            </span>
                            <span *ngIf="registerForm.controls.teacherPassword.touched && registerForm.controls.teacherPassword.errors?.minlength" class="text text-danger">
                                {{'The password must be at least 8 characters'|translate}}
                           </span> 

                           <span *ngIf="registerForm.controls.teacherPassword.touched && registerForm.controls.teacherPassword.errors?.maxlength" class="text text-danger">
                            {{'The password must not exceed 25 characters'|translate}}
                       </span> 
                            
                        </div>
                    </div>
                </div>

            <div class="row separator"></div>

                <div class="row">
                    <div class="col-md-12">
                        <h4 class="main-title txt-right">
                            {{'Student Information'|translate}}:-
                        </h4>
                    </div>
                </div>

                

                <div class="content"  formArrayName="students" *ngFor="let fields of registerForm.get('students')['controls']; let i=index">
                   
                    <div class="row" [formGroupName]="i">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input class="form-control" formControlName="studentName" placeholder="{{'Student Name'|translate}}" type="text"  > <!--[formControl]="name"-->
                                     
                                </div>
                                <div *ngIf="registerForm.get('students').controls[i].get('studentName').touched &&registerForm.get('students').controls[i].get('studentName').errors?.required" class="text text-danger">
                                    {{'Student Name'|translate}} {{'required'|translate}}
                               </div>
                                
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                    <input class="form-control" formControlName="parentPhoneNumber" placeholder="{{'Phone Number'|translate}}" type="text"  required> <!--[formControl]="name"-->
                                </div>
                                <div *ngIf="registerForm.get('students').controls[i].get('parentPhoneNumber').touched && registerForm.get('students').controls[i].get('parentPhoneNumber').errors?.required" class="text text-danger">
                                    {{'Phone Number'|translate}} {{'required'|translate}}
                               </div> 
                                <div *ngIf="registerForm.get('students').controls[i].get('parentPhoneNumber').touched && registerForm.get('students').controls[i].get('parentPhoneNumber').errors?.minlength" class="text text-danger">
                                    {{'The mobile must be at least 9 characters'|translate}}
                               </div> 
                               <div *ngIf="registerForm.get('students').controls[i].get('parentPhoneNumber').touched && registerForm.get('students').controls[i].get('parentPhoneNumber').errors?.maxlength" class="text text-danger">
                                   {{'The mobile must not exceed 11 characters'|translate}}
                               </div> 
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                    <input class="form-control" formControlName="parentPassword" placeholder="{{'password'|translate}}" [type]="studentpassword"  required> <!--[formControl]="name"-->
                                    <span toggle="#password-fielde" class="fa fa-fw fa-eye field-icon2 toggle-password"(click)="changPasswordVisiabiltystudent()">
                                    </span>
                                    <span *ngIf="registerForm.get('students').controls[i].get('parentPassword').touched &&registerForm.get('students').controls[i].get('parentPassword').errors?.minlength" class="text text-danger">
                                        {{'The password must be at least 8 characters'|translate}}
                                    </span> 
                                    <span *ngIf="registerForm.get('students').controls[i].get('parentPassword').touched &&registerForm.get('students').controls[i].get('parentPassword').errors?.maxlength" class="text text-danger">
                                        {{'The password must not exceed 25 characters'|translate}}
                                    </span> 
                                </div>
                                <div *ngIf="registerForm.get('students').controls[i].get('parentPassword').touched &&registerForm.get('students').controls[i].get('parentPassword').errors?.required" class="text text-danger">
                                    {{'password'|translate}} {{'required'|translate}}
                               </div> 

                               
                                
                            </div>

                            <div class="col-md-3" >
                                <div style="display: flex;">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="parentName" placeholder="{{'Parent Name'|translate}}" type="text"  required> <!--[formControl]="name"-->
                                    </div>
                                    
                                    <a class="add-btn" (click)="add()"> <i class="fa fa-plus icon-padding"></i></a>
                                  
                                    <a *ngIf="i > 0" class="delete-btn" (click)="removeProgram(i)"> <i class="fa fa-times"></i></a> 
                                   
                                </div>
                                
                                <div *ngIf="registerForm.get('students').controls[i].get('parentName').touched &&registerForm.get('students').controls[i].get('parentName').errors?.required" class="text text-danger">
                                    {{'Parent Name'|translate}} {{'required'|translate}}
                               </div> 
                            </div>
                      
                            
                       
                    </div>


                </div>


            <div class="row separator"></div>




                <div class="row">
                    <div class="col-md-12">
                        <button class="save-btn pull-right" type="submit">{{'save'|translate}}</button>
                    </div>
                </div>

                

            </form>
           </div>

        </div>
        
    </div>
</section>