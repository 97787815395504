
<div class="container-fluid mainstyle" id="contact">

  <div class="container">
      <div class="row purple-bg norow">
        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
        <!-- <p class="contact-parg">Recieve the latest updates on how to protect trails.</p>
        <p class="contact-parg">Subscribe to the monthly edition of paperless trails.</p> -->
  
        <form class="form-padding" [formGroup]="contactUsForm" (ngSubmit)="submit(contactUsForm.value)">
            
          <div class="form-group" >
           <input type="text" class="form-control custom-input" formControlName="name" name="name" placeholder="{{'HOME.NAME'|translate}}">
          
          <div *ngIf="contactUsForm.get('name').touched && contactUsForm.get('name').invalid">
            <div style="color: red;padding-bottom: 3px;"  *ngIf = "contactUsForm.get('name').errors.required">{{'HOME.REQUIRED'|translate}}</div>
          </div>
          <br>

            <input  type="email" class="form-control custom-input" formControlName="email"  name="email" placeholder="{{'HOME.EMAIL'|translate}}">
            <br>
            <div *ngIf="contactUsForm.get('email').touched && contactUsForm.get('email').invalid">
              <div style="color: red;padding-bottom: 3px;"  *ngIf = "contactUsForm.get('email').errors.required">{{'HOME.REQUIRED'|translate}}</div>
              <div style="color:red;padding-bottom: 3px" *ngIf = "contactUsForm.get('email').errors.pattern">{{'HOME.CORRECT_FORMATE'|translate}}</div>
            </div>

            <input  type="number" class="form-control custom-input" formControlName="mobile" name="mobile" placeholder="{{'HOME.MOBILE'|translate}}">
            <br>
            <input type="text" class="form-control custom-input"  formControlName="message_text" name="message_text" placeholder="{{'HOME.IWANT'|translate}}">
          </div>
            <br>

            <button [disabled]="!contactUsForm.valid" type="submit" class="form-btn" value="Send">{{'HOME.SEND'|translate}}</button>
  
          </form>
        
        </div>
        <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 mob hidden-xs">
            <img class="img-responsive mobile-img " src="assets/images/nursery_images/new-img/{{'IMG.CONTACT'|translate}}">
        </div>
        <!-- <img class="img-responsive mobile-img" src="../../../assets/images/nursery_images/blue-mobile.png"> -->
      </div>
  </div>  
  </div>
  <app-nursery-ads></app-nursery-ads>

  <div class="container-fluid powered" style="background-color: #555555 !important;">
  
    <div class="row row-top norow footer-space">

      <div class="col-lg-4 col-md-3 col-sm-12 col-xs-12 mob-center">
          <img class="img-responsive footer-logo" src="assets/images/nursery_images/new-img/logo_footer-new.png">

         <!-- <h2 class="logo">Seeds ...</h2> -->
          
          <a href="https://www.facebook.com/KidSeeds/" target="blank"> <i class="fa fa-facebook-f social-icon facebook-icon"></i> </a> 
           <a href=" https://www.instagram.com/kidseeds/" target="blank"> <i class="fa fa-instagram social-icon instagram-icon"></i> </a> 
          <!-- <a href="" target="blank"> <i class="fa fa-twitter social-icon"></i> </a>  -->
          <!-- <a href="https://www.linkedin.com/company/pentavalue" target="blank"><i class="fa fa-linkedin social-icon linkedin-icon"></i></a> -->
          <!-- <a href="" target=""> <i class="fa fa-whatsapp social-icon"></i> </a>  -->
      </div>
      <div class="col-lg-4 col-md-2 col-sm-12 col-xs-12 mob-center">
        <p class="title"> {{'HOME.EG_ADDRESS'|translate}}</p>
        <p class="paragraph">{{'HOME.EG_ADDRESS_VALUE'|translate}}</p>
        <p class="paragraph">+20 100 302 2122</p> 
      </div>

      <div class="col-lg-4 col-md-3 col-sm-12 col-xs-12 mob-center">
        <p class="title"> {{'HOME.SA_ADDRESS'|translate}}</p>
        <p class="paragraph">{{'HOME.SA_ADDRESS_VALUE'|translate}}</p>
        <p class="paragraph">0096655 202 9500</p>
        <!-- <p class="paragraph"></p> -->
          <!-- <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="200" id="gmap_canvas" src="https://maps.google.com/maps?q=ahmed%20tayseer%20street%20high%20point&t=&z=11&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </div><style>.mapouter{position:relative;text-align:$right;height:200px;width:100%;}.gmap_canvas {overflow:hidden;background:none!important;height:200px;width:100%;}</style></div> -->
      </div>


      <!-- <div class="col-lg-12">
        <p class="visitext">{{'Number of Visits'|translate}}<span class="novisit">{{no_visits}}</span></p>
      </div> -->

      
    </div>
  
  
  </div>

  <div class="container-fluid powered">
      <div class="container">
          <div class="row norow">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <p class="copyright footer-title3">{{'FOOTER.COPY_RIGHT'|translate}} - {{'FOOTER.ALL_RIGHT'|translate}}  <a class="pentavalue" href="http://www.pentavalue.com/">{{'FOOTER.PENTAVALUE'|translate}} </a></p>
            </div>
        </div>
    </div>
  </div>
  
