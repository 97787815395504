import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nursery-view-user',
  templateUrl: './nursery-view-user.component.html',
  styleUrls: ['./nursery-view-user.component.scss']
})
export class NurseryViewUserComponent implements OnInit {

  DATA:any;
  url:any;
  data_url

  constructor() { }

  ngOnInit(): void {
   this.DATA = localStorage.getItem('register');
   console.log("User Data :-", JSON.parse(this.DATA))
    this.data_url= JSON.parse(this.DATA)

   this.url = `${'https://kid-seeds.com/nursery_panel/public'}/${this.data_url.url}`;

   console.log("dashboard url:-", this.data_url.url)


  }

}
