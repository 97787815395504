
<div class="container-fluid mainstyle" id="values">
  <div class="container">

      <!-- <div class="pull-right">
            <img class="img-responsive pull-right pink-box loading  hidden-xs" src="assets/images/nursery_images/pink-small-square.png">
      </div> -->
        
    
      <div class="row norow">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 center">
          <img class="img-responsive" src="assets/images/nursery_images/images2/value-green-icon.png">
          <p class="main-title">{{'HEADER.FEATURES'|translate}}</p>
        </div>
        
      </div>
      
      <!-- row1 -->
      <div class="row norow">

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 arrow1 hidden-xs"[@expansionTrigger]="state">
            <p class="text-right title1">{{'HOME.COMMUNICATION'|translate}}</p>
            <p class="parg">{{'HOME.COMMUNICATION_NOTE'|translate}}</p>
            <!-- <img class="img-responsive right arrow1" src="../../../assets/images/nursery_images/value1-arrow.png"> -->
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 center hidden-xs" >
            <img class="img-responsive mov" src="assets/images/nursery_images/value1.png">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 center display-xs hidden-lg">
          <img class="img-responsive mov" src="assets/images/nursery_images/value1.png">
      </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 hidden-xs"></div>
        
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 arrow1 display-xs hidden-lg">
          <p class="text-right title1">{{'HOME.COMMUNICATION'|translate}}</p>
          <p class="parg">{{'HOME.COMMUNICATION_NOTE'|translate}}</p>
        </div>

      </div>
      <!-- row1 -->

      <!-- row2 -->
      <div class="row norow">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 center hidden-xs">
              <img class="img-responsive mov1" src="assets/images/nursery_images/value2.png">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 center display-xs hidden-lg" >
            <img class="img-responsive mov1" src="assets/images/nursery_images/value2.png">
        </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 arrow2 hidden-xs"[@scrollAnimation]="state">
              <!-- <img class="img-responsive " src="../../../assets/images/nursery_images/value2-arrow.png"> -->
              <p class="title2">{{'HOME.HEALTH'|translate}}</p>
              <p class="parg">{{'HOME.HEALTH_NOTE'|translate}}</p>

          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 arrow2 display-xs hidden-lg">
            <!-- <img class="img-responsive " src="../../../assets/images/nursery_images/value2-arrow.png"> -->
            <p class="title2">{{'HOME.HEALTH'|translate}}</p>
            <p class="parg">{{'HOME.HEALTH_NOTE'|translate}}</p>

        </div>

      </div>
      <!-- row2 -->

      <!-- row3 -->
      <div class="row norow">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 arrow3  hidden-xs"[@expansionTrigger]="state">
              <p class="text-right title3">{{'HOME.SAFETY'|translate}}</p>
              <p class="parg text-right">{{'HOME.SAFETY_NOTE'|translate}}</p>

              <!-- <img class="img-responsive" src="../../../assets/images/nursery_images/value3-arrow.png"> -->
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 center hidden-xs" >
              <img class="img-responsive mov" src="assets/images/nursery_images/value3.png">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 center display-xs hidden-lg">
            <img class="img-responsive mov" src="assets/images/nursery_images/value3.png">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 hidden-xs"></div>

          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 arrow3 display-xs hidden-lg">
            <p class="text-right title3">{{'HOME.SAFETY'|translate}}</p>
            <p class="parg text-right">{{'HOME.SAFETY_NOTE'|translate}}</p>

            <!-- <img class="img-responsive" src="../../../assets/images/nursery_images/value3-arrow.png"> -->
         </div>

      </div>
      <!-- row3 -->

      <!-- row4 -->
      <div class="row norow">

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 center hidden-xs">
            <img class="img-responsive mov1" src="assets/images/nursery_images/images2/value4-card.png">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 center display-xs hidden-lg">
          <img class="img-responsive mov1" src="assets/images/nursery_images/images2/value4-card.png">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 arrow4 hidden-xs"[@scrollAnimation]="state">
            <!-- <img class="img-responsive arrow4" src="../../../assets/images/nursery_images/value4-arrow.png"> -->
            <p class="title4">{{'HOME.LEARNING'|translate}}</p>
            <p class="parg no-margin-bottom">{{'HOME.LEARNING_NOTE'|translate}}</p>

        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 arrow4 display-xs hidden-lg">
          <!-- <img class="img-responsive arrow4" src="../../../assets/images/nursery_images/value4-arrow.png"> -->
          <p class="title4">{{'HOME.LEARNING'|translate}}</p>
          <p class="parg no-margin-bottom">{{'HOME.LEARNING_NOTE'|translate}}</p>

      </div>

      </div>
      <!-- row4 -->

      <!-- row5 -->
      <div class="row norow">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 arrow5 hidden-xs"[@expansionTrigger]="state">
            <p class="text-right title5">{{'HOME.BEHAVIOR'|translate}}</p>
            <p class="parg text-right">{{'HOME.BEHAVIOR_NOTE'|translate}}</p>
            <!-- <img class="img-responsive" src="../../../assets/images/nursery_images/value5-arrow.png"> -->
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 center hidden-xs">
            <img class="img-responsive mov" src="assets/images/nursery_images/images2/value5-card.png">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 center display-xs hidden-lg">
          <img class="img-responsive mov" src="assets/images/nursery_images/images2/value5-card.png">
      </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 hidden-xs"></div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 arrow5 display-xs hidden-lg">
          <p class="text-right title5">{{'HOME.BEHAVIOR'|translate}}</p>
          <p class="parg text-right">{{'HOME.BEHAVIOR_NOTE'|translate}}<p>
          <!-- <img class="img-responsive" src="../../../assets/images/nursery_images/value5-arrow.png"> -->
      </div>

      </div>
      <!-- row5 -->


  </div>

</div>
