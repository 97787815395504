import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
// import { SwiperModule } from 'angular2-useful-swiper';
// import { SwiperModule } from 'angular2-useful-swiper';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { RouterModule, Routes } from '@angular/router';
// import { NurseryComponent } from './pages/nursery/home/home.component';

// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import { NurseryComponent } from './pages/nursery/nursery.component';
import { NurseryHeaderComponent } from './General/nursery-header/nursery-header.component';
import { NurseryMainComponent } from './General/nursery-main/nursery-main.component';
import { NurseryAboutComponent } from './General/nursery-about/nursery-about.component';
import { NurseryValuesComponent } from './General/nursery-values/nursery-values.component';
import { NurseryManagersComponent } from './General/nursery-managers/nursery-managers.component';
import { NurseryTeachersComponent } from './General/nursery-teachers/nursery-teachers.component';
import { NurseryParentsComponent } from './General/nursery-parents/nursery-parents.component';
import { NurseryAppComponent } from './General/nursery-app/nursery-app.component';
import { NurseryAdsComponent } from './General/nursery-ads/nursery-ads.component';
import { NurserySubscriptionComponent } from './General/nursery-subscription/nursery-subscription.component';
import { NurseryContactComponent } from './General/nursery-contact/nursery-contact.component';
import { NurseryFooterComponent } from './General/nursery-footer/nursery-footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NurseryBioComponent } from './General/nursery-bio/nursery-bio.component';
import { RegisterComponent } from './pages/register/register.component';
import { NurseryRegisterComponent } from './General/nursery-register/nursery-register.component';
import { NurseryViewUserComponent } from './General/nursery-view-user/nursery-view-user.component';
import { ViewUserComponent } from './pages/view-user/view-user.component';

import { ToastrModule } from 'ngx-toastr';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const routes: Routes = [
  { path: '', component: NurseryComponent },
  {path: 'register', component: RegisterComponent},
  {path: 'view', component: ViewUserComponent}

];
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
@NgModule({
  declarations: [
    AppComponent,
    NurseryComponent,
    NurseryComponent,
    NurseryHeaderComponent,
    NurseryMainComponent,
    NurseryAboutComponent,
    NurseryValuesComponent,
    NurseryManagersComponent,
    NurseryTeachersComponent,
    NurseryParentsComponent,
    NurseryAppComponent,
    NurseryAdsComponent,
    NurserySubscriptionComponent,
    NurseryContactComponent,
    NurseryFooterComponent,
    NurseryBioComponent,
    RegisterComponent,
    NurseryRegisterComponent,
    NurseryViewUserComponent,
    ViewUserComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SwiperModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    // ngx-translate and the loader module
    HttpClientModule,
 
    TranslateModule.forRoot({
      defaultLanguage: 'en',

      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
    }),

    ToastrModule.forRoot(), // ToastrModule added

  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// // required for AOT compilation
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }