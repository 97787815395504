

<div class="container-fluid mainstyle">
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
        <!-- <img class="img-responsive logo-size" src="assets/images/nursery_images/new-img/logo-new.png"> -->
        <div class="logo-size" title="{{'HEADER.KIDSEEDS'|translate}}"></div>
        <!-- <h2 class="logo">Seeds ...</h2> -->
      </div> 
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 center">
        <div class="topnav" id="myTopnav" >
          <!-- <a href="#" class="closebtn" (click)="closeNav()">&times; </a> -->
          <a href="#home"  class="active  js-scroll-trigger scrol-smoothly" >{{'HEADER.HOME'|translate}}</a>
          <a routerLink="/register" class="mdbPageScroll scrol-smoothly">{{'HEADER.Register'|translate}}</a>
          <a href="#about" class="mdbPageScroll scrol-smoothly">{{'HEADER.ABOUT'|translate}}</a>
          <a href="#values" class="js-scroll-trigger scrol-smoothly">{{'HEADER.FEATURES'|translate}}</a>
          <!-- <a href="#tour" class="js-scroll-trigger scrol-smoothly">Tour</a> -->
          <a href="#theapp" class="js-scroll-trigger scrol-smoothly">{{'HEADER.THEAPP'|translate}}</a>
          <!-- <a href="#subscription" class="js-scroll-trigger scrol-smoothly">Subscription</a> -->
          <a href="#contact" class="js-scroll-trigger scrol-smoothly" >{{'HEADER.CONTACT'|translate}}</a>
          
          <!-- <select #langSelect  class="pull-left">
            <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
             <option *ngIf="lang == 'ar'" (click)="setLang('en')">En</option>
             <option *ngIf="lang == 'en'" (click)="setLang('ar')">Ar</option>

          </select> -->

          <ul class="lang-drop pull-left">
            <li><a *ngIf="lang == 'ar'" (click)="setLang('en')" class="lang">English <i class="fa fa-globe flagimg lang-icon"></i></a></li>
            <li><a *ngIf="lang == 'en'" (click)="setLang('ar')" class="lang">عربى    <i class="fa fa-globe flagimg  lang-icon"></i></a></li>
          </ul>
          
          <button class="icon" (click)="toggle()" id="btToggle" >
            <i class="fa fa-bars"></i>
          </button>
        </div>

        <!-- <div class="sidenav" id="mySidenav" *ngIf="show_dialog"> -->
        <div class="sidenav" id="mySidenav" >

          <a href="#home"  class="active  js-scroll-trigger scrol-smoothly" >{{'HEADER.HOME'|translate}}</a>
          <a routerLink="/register" class="mdbPageScroll scrol-smoothly">{{'HEADER.Register'|translate}}</a>
          <a href="#about" class="mdbPageScroll scrol-smoothly">{{'HEADER.ABOUT'|translate}}</a>
          <a href="#values" class="js-scroll-trigger scrol-smoothly">{{'HEADER.FEATURES'|translate}}</a>
          <!-- <a href="#tour" class="js-scroll-trigger scrol-smoothly">Tour</a> -->
          <a href="#theapp" class="js-scroll-trigger scrol-smoothly">{{'HEADER.THEAPP'|translate}}</a>
          <a href="#subscription" class="js-scroll-trigger scrol-smoothly">{{'HEADER.JOINUS'|translate}}</a>
          <a href="#contact" class="js-scroll-trigger scrol-smoothly" >{{'HEADER.CONTACT'|translate}}</a>
          <!-- <select #langSelectSide (change)="translate.use(langSelectSide.value)">
            <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
          </select> -->

         
          <ul class="lang-drop" style="padding: 15px 10px 0px 10px;">
            <li><a *ngIf="lang == 'ar'" (click)="setLang('en')" class="lang">English <i class="fa fa-globe flagimg lang-icon"></i></a></li>
            <li><a *ngIf="lang == 'en'" (click)="setLang('ar')" class="lang">عربى    <i class="fa fa-globe flagimg  lang-icon"></i></a></li>
          </ul>

        </div>
        
      </div>
      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 center hidden-md">
          <div class="top-social">
            <a href=" https://www.instagram.com/kidseeds/" target="blank"> <i class="fa fa-instagram social-icon instagram-icon"></i> </a> 
            <a href="https://www.facebook.com/KidSeeds/" target="blank"> <i class="fa fa-facebook-f social-icon facebook-icon"></i> </a> 
            <!-- <a href="" target="blank"> <i class="fa fa-twitter social-icon"></i> </a>  -->
            <!-- <a href="https://www.linkedin.com/company/pentavalue" target="blank"><i class="fa fa-linkedin social-icon linkedin-icon"></i></a> -->
            <!-- <a href="" target=""> <i class="fa fa-whatsapp social-icon"></i> </a>  -->
          </div>
        </div>

    </div>
</div>
