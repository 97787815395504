import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Kid-Seeds';
  constructor(private translate: TranslateService) {
    // translate.addLangs(["en", "ar"]);
    // translate.setDefaultLang('en');

    // let browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');


        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en')

        // the lang to use, if the lang isn't available, it will use the current loader to get them
       translate.use(localStorage.getItem('lang'));

}

  
}

