
<div class="container-fluid mainstyle" id="teacher">
  <div class="row norow">
    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 nopadding hidden-xs" [@expansionTrigger]="state">

        <div class="orange-bg grow">
          <p class="main-title1">{{'HOME.FOR'|translate}}</p>
          <p class="main-title2"> {{'HOME.TEACHER'|translate}}</p>
          </div>
            <img class="img-responsive teacher-img" src="assets/images/nursery_images/{{'IMG.TEACHER'|translate}}">

    </div>
    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 right-padding hidden-xs" [@scrollAnimation]="state">

        <img class="img-responsive bottom-space" src="assets/images/nursery_images/images2/green-text-icon.png">
     
        <p class="paragraph "> <span class="title">{{'HOME.HEALTH'|translate}} _ </span> {{'HOME.TEACHERS_HEALTH'|translate}} </p>
        <p class="paragraph"> <span class="title">{{'HOME.LEARNING'|translate}} _ </span> {{'HOME.TEACHERS_LEARNING'|translate}}</p>
        <p class="paragraph"> <span class="title">{{'HOME.COMMUNICATION'|translate}} _</span> {{'HOME.TEACHERS_COMMUNICATION'|translate}}</p>
        <p class="paragraph"> <span class="title">{{'HOME.BEHAVIOR'|translate}} _ </span>  {{'HOME.TEACHERS_BEHAVIOR'|translate}}</p>
        <p class="paragraph"> <span class="title"> {{'HOME.SAFETY'|translate}} _</span>{{'HOME.TEACHERS_SAFETY'|translate}}</p>
  
        <img class="img-responsive right" src="assets/images/nursery_images/images2/green-text-icon.png">
  
  

    </div>

    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 nopadding display-xs hidden-lg">

      <div class="orange-bg">
        <p class="main-title1">{{'HOME.FOR'|translate}}</p>
        <p class="main-title2"> {{'HOME.TEACHER'|translate}}</p>
        </div>
          <img class="img-responsive" src="assets/images/nursery_images/{{'IMG.TEACHER'|translate}}">

    </div>
    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 right-padding display-xs hidden-lg">

      <img class="img-responsive bottom-space" src="assets/images/nursery_images/images2/green-text-icon.png">
   
      <p class="paragraph "> <span class="title">{{'HOME.HEALTH'|translate}}_ </span> {{'HOME.TEACHERS_HEALTH'|translate}} </p>
      <p class="paragraph"> <span class="title">{{'HOME.LEARNING'|translate}}_ </span> {{'HOME.TEACHERS_LEARNING'|translate}}</p>
      <p class="paragraph"> <span class="title">{{'HOME.COMMUNICATION'|translate}}_</span> {{'HOME.TEACHERS_COMMUNICATION'|translate}}</p>
      <p class="paragraph"> <span class="title">{{'HOME.BEHAVIOR'|translate}}_ </span>  {{'HOME.TEACHERS_BEHAVIOR'|translate}}</p>
      <p class="paragraph"> <span class="title"> {{'HOME.SAFETY'|translate}}_</span>{{'HOME.TEACHERS_SAFETY'|translate}}</p>

      <img class="img-responsive right" src="assets/images/nursery_images/images2/green-text-icon.png">



    </div>

  </div>
</div>

