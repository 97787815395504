
<div class="container-fluid " id="home">

  <div class="main-style">
    <div class="row norow">
      <div class="text-style container hidden-xs">
          <div class="">
            <img  class="img-responsive pink-square" src="assets/images/nursery_images/images2/simon-small-box.png">
          </div>
          <div class="">
            <p class="main-text">{{'HOME.INTRO'|translate}}</p>
            
          </div>
          <!-- <p class="main-text">APPLICATION YOU'LL</p>
          <P class="main-text">EVER NEED!</P> -->
      </div>
    
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 nopadding center overlay">
          <div class="image1  full-size">
              <a class="button main-btn" href="#tour">{{'HOME.MANAGEMENT'|translate}}</a>
          </div>
              <!-- <img class="img-responsive full-size" src="assets/images/nursery_images/main1.png">
              <button class="button main-btn">Manager</button> -->

      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 nopadding center overlay">
          <div class="image2  full-size">
              <a class="button main-btn" href="#parent">{{'HOME.PARENT'|translate}}</a>
          </div>
          <!-- <img class="img-responsive full-size overlay" src="assets/images/nursery_images/main2.png">
          <button class="button main-btn">Parent</button> -->

      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 nopadding center overlay">
          <div class="image3  full-size">
              <a class="button main-btn" href="#teacher">{{'HOME.TEACHER'|translate}}</a>
          </div>

          <!-- <img class="img-responsive full-size overlay" src="assets/images/nursery_images/main3-new.png">
          <button class="button main-btn">Teacher</button> -->

      </div>
      <div class="clearfix"></div>
    </div>
    <!-- <img class="img-responsive center"  src="../../../assets/images/nursery_images/main-arrow.png">
    <img class="img-responsive white-bg" src="../../../assets/images/nursery_images/white-curve777.png"> -->
    <div class="curve"></div>
    <div class="curve-btn hidden-xs">
      <img class="img-responsive " src="assets/images/nursery_images/main-arrow.png">
    </div>
  </div>

</div>


