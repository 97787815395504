import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiserviceService } from 'src/app/apiservice.service';

@Component({
  selector: 'app-nursery-header',
  templateUrl: './nursery-header.component.html',
  styleUrls: ['./nursery-header.component.scss']
})
export class NurseryHeaderComponent implements OnInit {
  closeResult: string;
  public show_dialog: boolean = false;

  lang;

  constructor(
    public translate: TranslateService,
    private service: ApiserviceService
    ) {
    // translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');

    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
  }

  ngOnInit() {
    // localStorage.setItem('lang', 'en');
    // this.service.changedLang.emit('en');
    this.lang = localStorage.getItem('lang') ?localStorage.getItem('lang') : 'en';

  }
  
  toggle() {
    this.show_dialog = !this.show_dialog;

    // CHANGE THE TEXT OF THE BUTTON.
    if (this.show_dialog) {
      document.getElementById("mySidenav").style.display = "block";
      document.getElementById("mySidenav").style.width = "100%";
    }
    else {
      document.getElementById("mySidenav").style.display = "none";
      document.getElementById("mySidenav").style.width = "0";
    }
  }

  scrollToElement(element): void {
    element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
  }

  setLang(currentLanguage) {
    console.log("the Current Language", currentLanguage);
    localStorage.setItem('lang', currentLanguage);
    this.translate.use(currentLanguage);

    window.location.reload();


    // this.service.changedLang.emit(currentLanguage);
  }
}
