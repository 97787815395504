<div class="container-fluid " id="about">

  <div class="orange-bg">
    <div class="row norow">
      
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 nopadding no-animation hidden-xs" [@expansionTrigger]="state">
        <img class="img-responsive two-circles circle-animate" src="assets/images/nursery_images/images2/two-small-circles-new.png">
        <img class="img-responsive mob-img" src="assets/images/nursery_images/new-img/{{'IMG.ABOUT'|translate}}">

      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding hidden-xs">

        <div class="row norow">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <img class="img-responsive right question-icon" src="assets/images/nursery_images/images2/green-questionmark.png">
          </div>
        </div>

        <div class="row row-top row-bottom norow no-animation" [@scrollAnimation]="state">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img class="about-icon" src="assets/images/nursery_images/images2/manager-icon.png">

                <p class="icon-title">{{'HOME.MANAGEMENT'|translate}}</p>
                <p class="icon-text">{{'HOME.MANAGEMENT_NOTE'|translate}}</p>
            </div>

        </div>
        

        <div class="row row-bottom norow no-animation" [@scrollAnimation]="state">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img class="about-icon" src="assets/images/nursery_images/images2/teacher-icon-new.png">

                <p class="icon-title">{{'HOME.TEACHER'|translate}}</p>
                <p class="icon-text">{{'HOME.TEACHER_NOTE'|translate}}</p>


            </div>

        </div>

        <div class="row norow no-animation" [@scrollAnimation]="state">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img class="about-icon" src="assets/images/nursery_images/parent-icon-new.png">

                <p class="icon-title">{{'HOME.PARENT'|translate}}</p>
                <p class="icon-text">{{'HOME.PARENT_NOTE'|translate}}</p>


            </div>

        </div>

        

        
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 nopadding no-animation display-xs hidden-lg" >
        <img class="img-responsive" src="assets/images/nursery_images/new-img/mob1.png">

      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding display-xs hidden-lg">

        <div class="row norow">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mob-center">
            <img class="img-responsive right" src="assets/images/nursery_images/images2/green-questionmark.png">
          </div>
        </div>

        <div class="row row-top row-bottom norow no-animation mob-center">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img class="about-icon " src="assets/images/nursery_images/images2/manager-icon.png">

            
                <p class="icon-title">Management</p>
                <p class="icon-text">Who keeps track of the whole system.</p>


            </div>

        </div>
        

        <div class="row row-bottom norow no-animation mob-center">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img class="about-icon" src="assets/images/nursery_images/images2/teacher-icon-new.png">

                <p class="icon-title">Teacher</p>
                <p class="icon-text">Who follows up and takes care of the kids.</p>


            </div>

        </div>

        <div class="row norow no-animation mob-center">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img class="about-icon" src="assets/images/nursery_images/parent-icon-new.png">

                <p class="icon-title">Parents</p>
                <p class="icon-text">Who always worries and want the best for their kids.</p>


            </div>

        </div>
        

        
      </div>

    </div>
  </div>
</div>
