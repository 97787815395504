import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiserviceService } from 'src/app/apiservice.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-nursery-footer',
  templateUrl: './nursery-footer.component.html',
  styleUrls: ['./nursery-footer.component.scss']
})
export class NurseryFooterComponent implements OnInit {
  browserLang: string;
  contactUsForm:FormGroup;
  no_visits;
  
  constructor(private fb: FormBuilder,
    private service: ApiserviceService,
    public translate: TranslateService
  ) { 
    this.contactUsForm = fb.group({
      name: ['',Validators.required],
      email: ['',Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
      mobile: ['',Validators.compose([Validators.minLength(11),Validators.maxLength(11)])],
      message_text: ['']
    })
    
  }
  ngOnInit() {
    this.browserLang = localStorage.getItem('lang');
    this.service.changedLang.subscribe(
      res=>{
        console.log("Again", res);
        this.browserLang = res;
      }
    );
    console.log(this.browserLang,'From')

    this.service.GetVisits().subscribe((res:any)=>{
       this.no_visits = res.data.id;
       console.log("No. Of Visits of Website ==========>",this.no_visits)
    })
  }
  submit(formValue){
    console.log("formValue => ",formValue);
    
    this.service.createCustomer(formValue).subscribe(response=>{
      this.contactUsForm.reset();
      Swal.fire({
        icon: 'success',
        confirmButtonText: this.browserLang=='ar'?'موافق':'Ok',
        title: this.browserLang=='en'?'Success' :'نجاح',
        html: this.browserLang=='en'?'Message Sent Successfully <br/> We will contact You Soon':'تم ارسال الرسالة بنجاح \n سيتم الاتصال بك قريبا',
        
      })
    },error=>{
      Swal.fire({
        icon: 'error',
        confirmButtonText: this.browserLang=='ar'?'موافق':'Ok',
        title: this.browserLang=='en'?'Oops' :'فشل',
        html: this.browserLang=='en'?'Error happened':'فشل الارسال',
        
      })
    })
  }


}
