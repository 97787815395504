<section class="builder-section container page-margin-top">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h3 class="builder-main-title"> 
          {{'My Profile'|translate}}
        </h3>
        
        <div class="steps">

           <div class="">

            <div class="row">
                <div class="col-md-12">
                    <h4 class="greeting-title">
                        {{'Thank you for your registeration'|translate}}
                    </h4>
                </div>
             
            </div>

            <div class="row separator1"></div>


                <div class="row">
                    <div class="col-md-12">
                        <h4 class="main-title txt-right">
                            {{'Dashboard Information'|translate}}:-
                        </h4>
                    </div>
                 
                </div>

                <div class="row">
                    <div class="col-md-4" >
                        <img class="nursery-img" src="{{data_url.image}}">
                    </div>
                    <div class="col-md-4" style="display: flex; margin-top: 50px;">
                        <label class="tit">{{'Username'|translate}} :</label>
                        <p>{{data_url.username}}</p>
                        

                    </div>
                    <div class="col-md-4" style="display: flex; margin-top: 50px;">
                        <label class="tit">{{'Nursery Name'|translate}}:</label>
                            <p>{{data_url.display_name}}</p>

                    </div>
                </div>

              

                <div class="row separator1"></div>

               

                <!-- <div class="row">
                    <div class="col-md-12">
                        <h4 class="main-title txt-right">
                            {{'Student Information'|translate}}:-
                        </h4>
                    </div>
                 
               </div> -->

                <!-- <div class="row">
                    <div class="col-md-6">
                        <p>Omar Hazem</p>
                        <p>012467657676</p>

                    </div>
                    <div class="col-md-6">
                        <div class="">
                            <p>Hazem Mohamed</p>
                            <p>We434fgfg</p>
                        </div>

                    </div>
                </div> -->

                <!-- <div class="row separator"></div> -->

                <div class="row">
                    <div class="col-md-12">
                        <h4 class="main-title txt-right">
                            {{'The Links'|translate}}:-
                        </h4>
                    </div>
                 
               </div> 


                <div class="row">
                    <div class="col-md-2">
                        <a class="store-btn" target="_blank" href="https://apps.apple.com/kz/app/kid-seeds/id1486782497">
                            <img src="assets/images/nursery_images/ios-btn.png">
                        </a>
                    </div>
                    <div class="col-md-2">
                        <a class="store-btn" target="_blank" href="https://play.google.com/store/apps/details?id=com.pentavalue.seeds">
                            <img src="assets/images/nursery_images/googleplay-img.png">
                        </a>
                    </div>
                    <div class="col-md-2" style="margin-top: 6px;">
                        <a class="link store-btn" target="_blank" href="{{url}}">
                            <i class="fa fa-link icon-padding"></i>
                            {{'Dashboard link'|translate}}
                        </a>
                    </div>
                </div>

           </div>

        </div>
        
    </div>
  </section>
  