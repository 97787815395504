import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,FormBuilder,Validators, FormArray } from '@angular/forms';

import {Router} from '@angular/router';
import {ApiserviceService} from '../../apiservice.service';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-nursery-register',
  templateUrl: './nursery-register.component.html',
  styleUrls: ['./nursery-register.component.scss']
})
export class NurseryRegisterComponent implements OnInit {
  password;
  confirmPassword;
  studentpassword;


  gender_id

  registerForm:FormGroup;
 
  containers = [];
  // students: FormArray;

  sarray:FormArray;

  typeImage: any;
  imageName: string = '';
  image_url: any;
  file_base64: any;
  type: any;
  lang;

  


  constructor(private fb: FormBuilder,
              private router: Router,
              private service:ApiserviceService,
              private toastr: ToastrService) {
                this.registerForm = fb.group({
                  username: new FormControl('', [Validators.required,]),
                  nurseryName: new FormControl('', [Validators.required]),
                  password: ['', [Validators.required,  Validators.minLength(8), Validators.maxLength(25)   ]],
                  mobile: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(11)  ]],

                  logo:['', [Validators.required]],
                  
            
                  className: new FormControl('', [Validators.required ]),
                  teacherName: new FormControl('', [Validators.required ]),
                  teacherNumber: ['', [Validators.required ,Validators.minLength(9), Validators.maxLength(11) ]],
                  teacherPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(25)  ]],
            
                  students:  fb.array([this.createFields()])
            
            
            
            
                })
               }

  ngOnInit(): void {
    this.password = 'password';
    this.confirmPassword = 'password';
    this.studentpassword = 'password';

    this.lang = localStorage.getItem('lang')
  }

  createFields() {
    return this.fb.group({
      studentName:['', [Validators.required]],
      parentPhoneNumber:['', [Validators.required, Validators.minLength(9), Validators.maxLength(11)]],
      parentPassword:['', [Validators.required, Validators.minLength(8), Validators.maxLength(25) ]],
      parentName:['', [Validators.required]],
    })
  }

  add(){
    this.sarray  = this.registerForm.get('students') as FormArray;
    this.sarray.push(this.createFields());
    this.containers.push(this.containers.length);
  }
  
  removeProgram(StudentIndex){
    this.sarray = this.registerForm.get('students') as FormArray;
       this.sarray.removeAt(StudentIndex);
      this.containers.splice(0,1)
  
  }


  changPasswordVisiabilty() {
    if (this.password == 'password') {
      this.password = 'text'
    } else {
      this.password = 'password'
    }
  }

  changConfirmPasswordVisiabilty() {
    if (this.confirmPassword == 'password') {
      this.confirmPassword = 'text'
    } else {
      this.confirmPassword = 'password'
    }
  }

  changPasswordVisiabiltystudent(){
    if (this.studentpassword == 'password') {
      this.studentpassword = 'text'
    } else {
      this.studentpassword = 'password'
    }
  }

  readImage(event) {
    var files = event.target.files[0];
    this.typeImage=files.type;
    // console.log(this.typeImage);

    this.imageName = files.name;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.image_url = 'data:'+this.typeImage+';base64,' + btoa(binaryString);
  }


 

  submitForm(value:any){
    if (value.invalid && value.pristine) {
      value.markAllAsTouched()
    } else if (value.invalid && value.dirty) {
      Object.keys(value.controls).forEach(field => {
        const control = value.get(field);
        control.markAsTouched({onlySelf: true});
      });
      if (this.lang == 'en'){
      this.toastr.error('Please enter the rest of the data');
      console.log("form is still invalid")
      Object.keys(value.controls).forEach(field => {
        const control = value.get(field);
        control.markAsTouched({onlySelf: true});
      })

     }
     else if (this.lang == 'ar') {
      this.toastr.error('من فضلك ادخل باقي البيانات');
      console.log("form is still invalid")
   
      Object.keys(value.controls).forEach(field => {
        const control = value.get(field);
        control.markAsTouched({onlySelf: true});
      })


     }

    } else if (value.valid == true) {
      // call function of submit here
       let obj = this.registerForm.value;
       obj.logo = this.image_url;
       if (this.lang == 'en'){
        obj.lang_id = 1;
       }
       else {
        obj.lang_id = 2;
       }

        this.service.NurseryRegisteration(obj).subscribe((res:any)=>{
          console.log("The registration response :- ",res)
          if(res.status.code === 200){
            localStorage.setItem('register',JSON.stringify(res.content))
            this.toastr.success(res.status.message); // toasr services
            this.router.navigateByUrl('/view');

          }
          else if (res.status.code === 403){
            let validation_errors = res.status.validation_errors;  
            validation_errors.forEach(element => {

              if(element.field == 'username')
              {this.toastr.error(element.message)}
              else if (element.field == 'nurseryName')
              {this.toastr.error(element.message)}
              else if (element.field == 'password')
              {this.toastr.error(element.message)}
              else if (element.field == 'mobile')
              {this.toastr.error(element.message)}

              else if (element.field == 'className')
              {this.toastr.error(element.message)}
              else if (element.field == 'teacherName')
              {this.toastr.error(element.message)}
              else if (element.field == 'teacherNumber')
              {this.toastr.error(element.message)}
              else if (element.field == 'teacherPassword')
              {this.toastr.error(element.message)}

              else if (element.field == 'students.0.parentPhoneNumber')
              {this.toastr.error(element.message)}
              else if (element.field == 'students.1.parentPhoneNumber')
              {this.toastr.error(element.message)}
              else if (element.field == 'students.2.parentPhoneNumber')
              {this.toastr.error(element.message)}
              else if (element.field == 'students.3.parentPhoneNumber')
              {this.toastr.error(element.message)}
              else if (element.field == 'students.4.parentPhoneNumber')
              {this.toastr.error(element.message)}

              else if (element.field == 'students.0.parentPassword')
              {this.toastr.error(element.message)}
              else if (element.field == 'students.1.parentPassword')
              {this.toastr.error(element.message)}
              else if (element.field == 'students.2.parentPassword')
              {this.toastr.error(element.message)}
              else if (element.field == 'students.3.parentPassword')
              {this.toastr.error(element.message)}
              else if (element.field == 'students.4.parentPassword')
              {this.toastr.error(element.message)}



            })


          }
          else {
            this.toastr.error(res.status.error_details)
          }
        })
      
      // end function of submit here
    }
    
  }


  // submit(formValue){
  //   // let obj={
  //   //  this.formValue.logo= this.image_url;
  //   // }

  //   let obj = this.registerForm.value;
  //   obj.logo = this.image_url;


  //   this.service.NurseryRegisteration(obj).subscribe((res:any)=>{
  //     console.log("The registration response :- ",res)
  //     if(res.status.code === 200){
  //       localStorage.setItem('register',JSON.stringify(res.content))
  //       this.toastr.success(res.status.message); // toasr services
  //       this.router.navigateByUrl('/view');

  //     }
  //     else if (res.status.code === 403){
  //       let validation_errors = res.status.validation_errors;  
  //       validation_errors.forEach(element => {

  //         if(element.field == 'username')
  //          {this.toastr.error(element.message)}
  //         else if (element.field == 'nurseryName')
  //          {this.toastr.error(element.message)}
  //         else if (element.field == 'password')
  //          {this.toastr.error(element.message)}
  //         else if (element.field == 'mobile')
  //          {this.toastr.error(element.message)}

  //          else if (element.field == 'className')
  //          {this.toastr.error(element.message)}
  //          else if (element.field == 'teacherName')
  //          {this.toastr.error(element.message)}
  //          else if (element.field == 'teacherNumber')
  //          {this.toastr.error(element.message)}
  //          else if (element.field == 'teacherPassword')
  //          {this.toastr.error(element.message)}

  //          else if (element.field == 'students.0.parentPhoneNumber')
  //          {this.toastr.error(element.message)}
  //          else if (element.field == 'students.1.parentPhoneNumber')
  //          {this.toastr.error(element.message)}
  //          else if (element.field == 'students.2.parentPhoneNumber')
  //          {this.toastr.error(element.message)}
  //          else if (element.field == 'students.3.parentPhoneNumber')
  //          {this.toastr.error(element.message)}
  //          else if (element.field == 'students.4.parentPhoneNumber')
  //          {this.toastr.error(element.message)}

  //          else if (element.field == 'students.0.parentPassword')
  //          {this.toastr.error(element.message)}
  //          else if (element.field == 'students.1.parentPassword')
  //          {this.toastr.error(element.message)}
  //          else if (element.field == 'students.2.parentPassword')
  //          {this.toastr.error(element.message)}
  //          else if (element.field == 'students.3.parentPassword')
  //          {this.toastr.error(element.message)}
  //          else if (element.field == 'students.4.parentPassword')
  //          {this.toastr.error(element.message)}



  //       })


  //     }
  //     else {
  //       this.toastr.error(res.status.error_details)
  //     }
  //   })


  // }

}
