<div class="container-fluid">
  <div class="container">
    
  <div class="bio row">
    <div class="col-md-9 col-sm-10 col-xs-12">
      <div class="bio-text">
        <h3> {{'HOME.BIOGRAPHY'|translate}}</h3>
        <p>
          {{'HOME.BIO'|translate}}
        </p>
      </div>
    </div>  
    <div class="col-md-3 col-sm-2 col-xs-12">
      <div class="animated-border ">
        <span class="logo-d"></span>
      </div>
    </div>
  </div>
  </div>
</div>