<div class="container-fluid">
  <app-nursery-header></app-nursery-header>
  <app-nursery-main></app-nursery-main>
  <app-nursery-about></app-nursery-about>
  <app-nursery-bio></app-nursery-bio>
  <app-nursery-values></app-nursery-values>
  <app-nursery-managers></app-nursery-managers>
  <app-nursery-teachers></app-nursery-teachers>
  <app-nursery-parents></app-nursery-parents>
  <app-nursery-app></app-nursery-app>
  <app-nursery-contact></app-nursery-contact>
  <!-- <app-nursery-ads></app-nursery-ads> -->
  <app-nursery-footer></app-nursery-footer>
</div>
