<br>
  <br>
<div class="container-fluid mainstyle">
  <div class="container">
    <div class="row norow">
      
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p class="main-title">{{'HOME.GET_APP'|translate}}</p>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p class="title2">{{'HOME.GET_APP_NOTE'|translate}}</p>
      </div>

      <div class="col-md-12 col-sm-12 col-xs-12">
          <ul class="list-inline">

            
            <li class="item-list">
              <a class="store-btn" href="https://apps.apple.com/kz/app/kid-seeds/id1486782497">
                <img src="assets/images/nursery_images/ios-btn.png">
              </a>
              </li>
          
          
            <li class="item-list">
              <a class="store-btn" href="https://play.google.com/store/apps/details?id=com.pentavalue.seeds">
              <img src="assets/images/nursery_images/googleplay-img.png">
            </a>
          </li>
            

          </ul>

        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">

        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>
    </div>
  </div>
</div>
